import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Admin';

  constructor() {
    moment.locale('ru');

    moment.locale('en', {
      calendar : {
        lastDay : '[Yesterday at] LT',
        sameDay : '[Today at] LT',
        nextDay : '[Tomorrow at] LT',
        lastWeek : 'L',
        nextWeek : 'dddd [at] LT',
        sameElse : 'L'
      }
    });

    moment.locale('ru', {
      calendar : {
        lastDay : '[Вчера в] LT',
        sameDay : '[Сегодня в] LT',
        nextDay : '[Завтра в] LT',
        lastWeek : 'L',
        nextWeek : 'dddd [в] LT',
        sameElse : 'L'
      }
    });
  }
}
