import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update-agency-email-dialog',
  templateUrl: './update-agency-email-dialog.component.html',
  styleUrls: ['./update-agency-email-dialog.component.scss']
})
export class UpdateAgencyEmailDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false; 

  constructor(
    private dialogRef: MatDialogRef<UpdateAgencyEmailDialogComponent>,
    private http: HttpService
  ) {

    const email = new FormControl('', [
      Validators.email
    ]);

    this.groupForm = new FormGroup({
      email
    });
  }

  ngOnInit() {
  }

  updateEmail() {
    if(this.groupForm.invalid || this.isRequested) return;

    let email: string = this.groupForm.controls.email.value;

    this.http.patch(
      HttpRequests.PATCH_SETTINGS_UPDATE_EMAIL,
      { email }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}