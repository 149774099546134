<section class="snackbar">
  <img 
    class="snackbar-icon"
    *ngIf="data.type === 0"
    src="assets/snackbar/fail.svg" 
    alt="Fail notify"
  >
  <img 
    class="snackbar-icon"
    *ngIf="data.type === 1"
    src="assets/snackbar/success.svg" 
    alt="Success notify"
  >

  <p class="snackbar-text">
    {{ data.text }}
  </p>

  <button class="close" (click)="snackBarRef.dismiss()">
    <img src="assets/icons/close.svg" alt="Close popup">
  </button>
</section>
