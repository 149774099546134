import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss']
})
export class MainWrapperComponent implements OnInit {
  public swipe$: Subject<any> = new Subject()


  constructor() { }

  ngOnInit() {
  }

  onSwipeLeft(event) {
    if(window.innerWidth > 768) return;

    this.swipe$.next(event.type)
  }

  onSwipeRight(event) {
    if(window.innerWidth > 768) return;

    this.swipe$.next(event.type)
  }



}
