<section class="view-avatars-dialog">
  <button class="close" mat-dialog-close>
    <img src="assets/icons/close.svg" alt="Close popup">
  </button>

  <section class="avatars">
    <div *ngFor="let avatar of user?.celebAvatars" class="image-wrap">
      <img
        class="avatar"        
        (click)="viewImage(avatar.avatarFull)"
        [src]="avatar.avatarMedium"
        alt="Avatar"
      />
    </div>
  </section>
</section>