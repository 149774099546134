import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpRequests } from '../enums/http-requests';

@Injectable({ providedIn: 'root' })
export class HttpService {
  private url: string = environment.apiServerUrl;

  constructor(private http: HttpClient) {}

  post<T>(url: HttpRequests | string, data, headers: any = null, params: any = null): Observable<T> {
    headers = new HttpHeaders(this.formatHeaders(headers));
    const body = this.parseBody(data);

    return this.http.post<T>(this.url + url, body, {
      headers,
      params,
      withCredentials: true,
    });
  }

  patch<T>(url: HttpRequests | string, data, headers: any = null, params: any = null): Observable<T> {
    headers = new HttpHeaders(this.formatHeaders(headers));
    const body = this.parseBody(data);

    return this.http.patch<T>(this.url + url, body, {
      headers,
      params,
      withCredentials: true,
    });
  }

  get<T>(url: HttpRequests | string, headers: any = null, params: any = {}): Observable<T> {
    headers = new HttpHeaders(this.formatHeaders(headers));

    (url as string) += url.includes('?') ? '&_=' + Date.now() : '?_=' + Date.now();

    return this.http.get<T>(this.url + url, {
      headers,
      params,
      withCredentials: true,
    });
  }

  delete<T>(url: HttpRequests | string, headers: any = null, params: any = null): Observable<T> {
    headers = new HttpHeaders(this.formatHeaders(headers));

    return this.http.delete<T>(this.url + url, {
      headers,
      withCredentials: true,
      params,
    });
  }

  private parseBody(body) {
    const formData = new FormData();

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const element = body[key];
        if (element || element === '') {
          formData.append(key, element);
        }
      }
    }

    return formData;
  }

  private formatHeaders(headers) {
    if (!headers) return;

    for (const key in headers)
      if (headers.hasOwnProperty(key))
        if (typeof headers[key] !== 'string') headers[key] = headers[key] + '';

    return headers;
  }
}
