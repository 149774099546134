import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, timer, Observer } from 'rxjs';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { DialogService } from './dialog.service';
import { LoggerService } from './logger.service';
import { ICodeResponce } from '../interfaces/code-responce.interface';
import { HttpRequests } from '../enums/http-requests';
import { tap } from 'rxjs/operators';
import { Cookies } from '../enums/cookies.enum';
import { CookieService } from 'ngx-cookie';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public signIn$: Subject<any> = new Subject();
  public signUp$: Subject<any> = new Subject();
  public signOut$: Subject<any> = new Subject();

  public isOwner: boolean;
  public isLoadingСontent: boolean;

  constructor(
    private cookie: CookieService,
    private http: HttpService,
    private router: Router,
    private dialog: DialogService,
    private logger: LoggerService,
  ) { }

  signIn(email: string, password: string, isOwner: boolean): Observable<ICodeResponce> {
    this.logger.log('Signin in auth service');
    email = email.toLowerCase();

    let apiHandler: string = HttpRequests.POST_SIGN_IN;

    // if (isOwner) {
    //   apiHendler = HttpRequests.POST_SIGN_IN_OWNER

    //   this.isOwner = true;
    // } else {
    //   apiHendler = HttpRequests.POST_SIGN_IN_OPERATOR;

    //   this.isOwner = false;
    // }

    return this.http
      .post<ICodeResponce>(apiHandler, {
        email,
        password,
      })
      .pipe(
        tap((data) => {
          this.cookie.put(Cookies.ACCESS_TOKEN, (data as any)['token'], { expires: new Date(Date.now() + (1000 * 60 * 60 * 24 * 365)) });
          this.cookie.put(Cookies.ACCESS_ADMIN, (data as any)['isAdmin'], { expires: new Date(Date.now() + (1000 * 60 * 60 * 24 * 365)) });
          this.cookie.put(Cookies.ACCESS_LOADING_СONTENT, (data as any)['isLoadingСontent'], { expires: new Date(Date.now() + (1000 * 60 * 60 * 24 * 365)) });
          this.signIn$.next();
          // if('code' in data) {
          // } else {

          // }
        }),
      );
  }

  public isLogged = () => !!this.cookie.get(Cookies.ACCESS_TOKEN)

  signOut(withRedirect: boolean = true) {
    if(!this.isLogged()) return;    

    this.logger.warn('LOG OUT');
    this.signOut$.next('');
    // this.user.resetUser();

    this.cookie.put(Cookies.ACCESS_TOKEN, '');
    this.cookie.remove(Cookies.ACCESS_TOKEN);

    this.cookie.put(Cookies.ACCESS_ADMIN, '');
    this.cookie.remove(Cookies.ACCESS_ADMIN);

    this.cookie.put(Cookies.ACCESS_LOADING_СONTENT, '');
    this.cookie.remove(Cookies.ACCESS_LOADING_СONTENT);

    if (withRedirect) {
      this.router.navigateByUrl('/auth').then((data) => {
        if (!data) {
          this.router.navigateByUrl('/auth');
        }
      });
    }

    this.dialog.dialog.closeAll();
  }
}
