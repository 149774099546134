import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoggerService {

  constructor() {
  }

  log(...data) {
    if(data.length === 1) {
      console.log(`${this.getStaskTraceInstanceName(new Error())}: `, data[0]);
      return;
    }

    console.log(`${this.getStaskTraceInstanceName(new Error())}: `, data);
  }

  warn(...data) {
    if(data.length === 1) {
      console.warn(`${this.getStaskTraceInstanceName(new Error())}: `, data[0]);
      return;
    }

    console.warn(`${this.getStaskTraceInstanceName(new Error())}: `, data);
  }

  error(...data) {
    if(data.length === 1) {
      console.error(`${this.getStaskTraceInstanceName(new Error())}: `, data[0]);
      return;
    }

    console.error(`${this.getStaskTraceInstanceName(new Error())}: `, data);
  }

  getStaskTraceInstanceName(error: Error): string {
    // return error + '';

    let partOfStask = error.stack;

    const firstInstanceIndex = partOfStask.indexOf(' at ');
    const secondInstanceIndex = partOfStask.indexOf(' at ', firstInstanceIndex + 1);
    const thirdInstanceIndex = partOfStask.indexOf(' at ', secondInstanceIndex + 1);

    partOfStask = partOfStask.slice(secondInstanceIndex, thirdInstanceIndex)

    if(partOfStask === '') return 'LOG';

    const splittedParfOfStack = partOfStask.split(' ');

    const splittedCurrentInstance = splittedParfOfStack[splittedParfOfStack.length - 4].split('/');

    const result = splittedCurrentInstance[splittedCurrentInstance.length - 1]
      .slice(0, splittedCurrentInstance[splittedCurrentInstance.length - 1].length - 2)

    return result;
  }
}
