import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-operator-new-password-dialog',
  templateUrl: './operator-new-password-dialog.component.html',
  styleUrls: ['./operator-new-password-dialog.component.scss']
})
export class OperatorNewPasswordDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { operatorID: string},
    private dialogRef: MatDialogRef<OperatorNewPasswordDialogComponent>,
    private http: HttpService,
  ) {

    const newPassword = new FormControl('', [
      Validators.required
    ]);

    this.groupForm = new FormGroup({
      newPassword,
    });
  }

  ngOnInit() {
  }

  updatePassword() {
    if(this.groupForm.invalid || this.isRequested) return;

    let password: string = this.groupForm.controls.newPassword.value;

    this.http.patch(
      HttpRequests.PATCH_OPERATOR_PASSWORD.replace('|id|', this.data.operatorID),
      { password }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}