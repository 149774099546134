import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { Inject, Injector, Injectable } from '@angular/core';
import { Cookies } from '../enums/cookies.enum';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../services/auth.service';
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cookie = this.injector.get(CookieService);
    const auth = this.injector.get(AuthService);

    if(auth.isLogged() && !cookie.get(Cookies.REAL_USER)) cookie.put(Cookies.REAL_USER, '1');

    let at = cookie.get(Cookies.ACCESS_TOKEN);
    if (at) return next.handle(request.clone({ setHeaders: { 'agency-token' : at }, setParams: { '_rlusr' : '1' } }));

    return next.handle(request);
  }
}
