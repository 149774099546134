<section class="dialog">
  <h2 class="dialog-title">Модель {{ activeAgencyModel?.nickname }}</h2>
  
  <div class="info">
    <div class="section">
      <h3>💰 Доход (без вычета любых %)</h3>
      <p>За 24 часа: {{ activeAgencyModel?.earnedIn24Hours }}</p>
      <p>За 7 дней: {{ activeAgencyModel?.earnedInWeek }}</p>
      <p>За все время: {{ activeAgencyModel?.earnedAllTime }}</p>

      <h3>🚀 Активность</h3>
      <p>Зарегистрирована: {{ activeAgencyModel?.registeredAt }}</p>
      <p>Последний онлайн: {{ activeAgencyModel?.lastOnlineAtReal }}</p>
      
      <h3>🖼 Контент</h3>
      <p>Последняя рассылка создана: {{ activeAgencyModel?.lastPostedMassMessageAt }}</p>
      <p>Последний пост создан: {{ activeAgencyModel?.lastPostedContentAt }}</p>
    </div>
    <div class="section">
      <h3>💬 Общение</h3>
      <p>Последнее отправленное сообщение: {{ activeAgencyModel?.lastSendedMessageAt }}</p>
      <p>
        Непрочитанных сообщений от пользователей: {{ activeAgencyModel?.quantityUnreadMessagesUnreaded }}
      </p>
      <p>
        Пользователи ожидают ответа: {{ activeAgencyModel?.quantityUnreadMessagesUnanswered }}
      </p>
      <p>
        Сообщений отправлено за час: {{ activeAgencyModel?.quantityMessagesSendedInHour }}
      </p>
      <p>
        Сообщений отправлено за день: {{ activeAgencyModel?.quantityMessagesSendedIn24Hours }}
      </p>
    </div>
  </div>
</section>
