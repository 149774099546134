import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-group-dialog',
  templateUrl: './create-new-group-dialog.component.html',
  styleUrls: ['./create-new-group-dialog.component.scss']
})
export class CreateNewGroupDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false;
  public groupType: any;

  constructor(
    private dialogRef: MatDialogRef<CreateNewGroupDialogComponent>,
    private http: HttpService
  ) {

    const agencyGroupName = new FormControl('', [
      Validators.required
    ]);

    this.groupForm = new FormGroup({
      agencyGroupName
    });
  }

  ngOnInit() {
  }

  selectGroupType(event: any) {
    this.groupType = event.target.value;
  }

  createGroup() {
    if(this.groupForm.invalid || this.isRequested) return;

    let agencyGroupName: string = this.groupForm.controls.agencyGroupName.value;

    this.http.post(
      HttpRequests.POST_GROUPS,
      { agencyGroupName, groupType: this.groupType }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}