<section class="add-to-recommended-dialog dialog">
  <h2 class="dialog-title">Изменить пароль</h2>

  <form class="id-form" [formGroup]="groupForm">
    <div class="input-field">
      <input
        type="password"
        placeholder="старый пароль"
        formControlName="oldPassword"
        id="id"
      />
    </div>
    <div class="input-field">
      <input
        type="password"
        placeholder="новый пароль"
        formControlName="newPassword"
        id="id"
      />
    </div>
  </form>

  <section class="actions">
    <button 
      class="button full confirm" 
      (click)="updatePassword()"
    >
      Изменить
    </button>

    <button 
      mat-dialog-close 
      class="button full reject"
    >
      Отменить
    </button>
  </section>
</section>
