import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-video-dialog',
  templateUrl: './view-video-dialog.component.html',
  styleUrls: ['./view-video-dialog.component.scss']
})
export class ViewVideoDialogComponent implements OnInit, OnDestroy {
  private unsubscriber$: Subject<any> = new Subject();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { videoUrl: string, thumbUrl: string },
    public dialogRef: MatDialogRef<ViewVideoDialogComponent>,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
