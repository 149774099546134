import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Os } from '../enums/os.enum';
import { getDeviceInfo } from '../extensions/device-info';
import { LoggerService } from './logger.service';

@Injectable()
export class DialogService {
  private lastOpenedDialog: ComponentType<any> | TemplateRef<any>;
  private lastOpenedDialogRef: MatDialogRef<any>;
  private lastOpenedDialogRef$: BehaviorSubject<MatDialogRef<any>> = new BehaviorSubject(null);
  private dialogIsOpen: boolean = false;

  constructor(
    public dialog: MatDialog,
    private logger: LoggerService,
    private router: Router,
    private location: Location,
  ) { }

  private afterClosedSubscription: Subscription;
  public open(
    dialog: ComponentType<any> | TemplateRef<any>,
    config: MatDialogConfig = {},
    closePrev: boolean = true,
  ) {
    this.logger.warn('Open dialog');
    if(this.lastOpenedDialog === dialog && this.dialogIsOpen) {
      this.logger.log('Dialog is already opened');
      return;
    }

    this.logger.warn('config', config);

    if(!('autoFocus' in config)) config.autoFocus = false;

    // if (config.panelClass && config.panelClass instanceof Array) {
    //   config.panelClass.push(this.theme.Theme);
    // } else if (config.panelClass && !(config.panelClass instanceof Array)) {
    //   config.panelClass = [config.panelClass, this.theme.Theme];
    // } else {
    //   config.panelClass = this.theme.Theme;
    // }

    if(closePrev) this.dialog.closeAll();

    this.lastOpenedDialog = dialog;
    this.lastOpenedDialogRef = this.dialog.open(dialog, config);
    this.lastOpenedDialogRef$.next(this.lastOpenedDialogRef);
    this.dialogIsOpen = true;

    // console.error((dialog as ComponentType<any>).name)

    const isIOS = getDeviceInfo().os === Os.iOS;

    if(!isIOS) {
      window.history.pushState('dialog', (dialog as ComponentType<any>).name);
      // console.error('PUSH')
    }

    // this.router.navigate([this.location.path()], {
    //   fragment: 'dialog',
    // });

    if(this.afterClosedSubscription) this.afterClosedSubscription.unsubscribe();
    this.afterClosedSubscription = this.lastOpenedDialogRef
      .afterClosed()
      // .pipe(tap(() => console.warn(window.history, window.history.state === null)))
      .pipe(tap(() => {
        // if(window.history.state === null) {
        // console.error(window.history.state)
        if(window.history.state === 'dialog' && !isIOS) {
          // console.error('BACK')
          window.history.back()
        }
      }))
      // .pipe(tap(() => window.history.back()))
      // .pipe(tap(() => this.location.replaceState(this.location.path())))
      // .pipe(tap(() => this.router.navigate([this.location.path()])))
      .subscribe(() => (this.dialogIsOpen = false));
  }

  public close(message) {
    this.dialogIsOpen = false;
    if(this.lastOpenedDialogRef) {
      this.lastOpenedDialogRef.close(message);
    }
  }

  public close$(): Observable<any> {
    if(this.lastOpenedDialogRef) {
      return this.lastOpenedDialogRef.afterClosed();
    }
    return this.lastOpenedDialogRef$.pipe(
      take(1),
      switchMap((data) => data.afterClosed()),
    );
  }

  private getDialogRef(): MatDialogRef<any> {
    return this.lastOpenedDialogRef;
  }
}
