<section class="add-to-recommended-dialog dialog">
  <h2 class="dialog-title">Добавить нового оператора</h2>

  <form class="id-form" [formGroup]="groupForm">
    <div class="input-field">
      <input
        type="text"
        placeholder="Имя оператора"
        formControlName="operatorName"
        id="id"
      />
    </div>
    <div class="input-field">
      <input
        type="number"
        placeholder="% выплат оператору (5%-95%)"
        formControlName="operatorPercentage"
        id="id"
      />
    </div>
    <div class="input-field">
      <input
        type="email"
        placeholder="Email оператора"
        formControlName="email"
        id="id"
      />
    </div>
    <div class="input-field">
      <input
        type="password"
        placeholder="Пароль оператора"
        formControlName="password"
        id="id"
      />
    </div>

    <select (change)="selectOperatorType($event)" class="type-select">
      <option [value]="0"><span>Работа с моделями</span></option>
      <option [value]="1"><span>Работа с контентом</span></option>
    </select>
  </form>

  <section class="actions">
    <button 
      class="button full confirm" 
      (click)="createOperator()"
    >
      Добавить
    </button>

    <button 
      mat-dialog-close 
      class="button full reject"
    >
      Отменить
    </button>
  </section>
</section>
