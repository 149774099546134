export enum HttpRequests {
  POST_SIGN_IN = '/sign-in',
  POST_SIGN_IN_OWNER = '/sign-in/owner',
  POST_SIGN_IN_OPERATOR = '/sign-in/operator',

  GET_PROFILE_AGENCY = '/profile/agency',
  GET_PROFILE_OPERATOR = '/profile/operator',

  PATCH_SETTINGS_UPDATE_NAME = '/settings/name',
  PATCH_SETTINGS_UPDATE_PASSWORD = '/settings/password',
  PATCH_SETTINGS_UPDATE_EMAIL = '/settings/email',
  PATCH_SETTINGS_UPDATE_PAYOUT = '/settings/payout',
  PATCH_SETTINGS_UPDATE_BONUSES = '/settings/bonuses',

  GET_MODELS = '/models',
  POST_MODELS = '/models',
  GET_MODEL = '/models/|id|',
  PATCH_MODEL_RESET_TOKEN = '/models/|id|/token',
  PATCH_UPDATE_MODEL_NICKNAME = '/models/|id|/nickname',
  PATCH_UPDATE_MODEL_WORK_INFO = '/models/|id|/work-info',
  DELETE_MODEL = '/models/|id|',

  GET_MODEL_POSTS = '/posts',

  GET_MODEL_GALLERY = '/gallery',
  POST_MODEL_GALLERY_ITEM = '/gallery',
  DELETE_MODEL_GALLERY_ITEM = '/gallery/|id|',
  UPDATE_MODEL_GALLERY_ITEM_PUBLISHED = '/gallery/|id|/published-switch',

  GET_MODEL_MESSAGES = '/messages',

  GET_MODEL_MONEY = '/finance/money-log',
  GET_OPERATORS_WITHDRAWAL = '/finance/operator-withdrawal',
  
  GET_GROUPS = '/groups',
  POST_GROUPS = '/groups',
  GET_GROUP = '/groups/|id|',
  DELETE_GROUP = '/groups/|id|',
  POST_GROUP_MODEL = '/groups/|id|/model',
  DELETE_GROUP_MODEL = '/groups/|id|/model',
  POST_GROUP_OPERATOR = '/groups/|id|/operator',
  DELETE_GROUP_OPERATOR = '/groups/|id|/operator',

  GET_OPERATORS = '/operators',
  POST_OPERATORS = '/operators',
  PATCH_OPERATOR_RESET_BALANCE = '/operators/|id|/reset-balance',
  PATCH_OPERATOR_PERCENTAGE = '/operators/|id|/percentage',
  DELETE_OPERATOR = '/operators/|id|',
  PATCH_OPERATOR_PASSWORD = '/operators/|id|/new-password',

  /**
   * OLD
   */

  GET_POST = '/posts/|id|',
  PATCH_HIDE_POST = '/posts/|id|/hide-switch',
  DELETE_HIDE_TEXT = '/posts/|id|/text',
  PATCH_MADE_FOR_FANS = '/posts/|id|/public-switch',
  
  POST_CREATE_AGENCY = '/agencies',

  GET_USERS_LIST = '/users',
  GET_USER = '/users/|id|',
  PATCH_RESET_USER_ONLINE = '/users/|id|/online/up',
  PATCH_CHANGE_CELEB_STATUS = '/users/|id|/celeb-moderation-status',
  PATCH_UPDATE_BALANCE = '/users/|id|/balance',
  PATCH_RESET_AVATAR = '/users/|id|/avatar/reset',
  PATCH_RESET_COVER = '/users/|id|/cover/reset',
  PATCH_RESET_NICKNAME = '/users/|id|/nickname/reset',
  PATCH_RESET_BIO = '/users/|id|/bio/reset',
  PATCH_RESET_MESSAGE_PRICE = '/users/|id|/message-price/reset',
  PATCH_RESET_SUBSCRIBE_PRICE = '/users/|id|/message-price/reset',
  PATCH_RESET_PASSWORD = '/users/|id|/password/reset',
  PATCH_PERSONAL_CELEB_TOKEN_PRICE_PERCENTAGE = '/users/|id|/personal-celeb-token-price-percentage',
  PATCH_AFFILIATE_PERCENTAGE = '/users/|id|/affiliate-percentage',
  PATCH_BAN_USER = '/users/|id|/ban',
  PATCH_ADD_USER_TO_RECOMMENDED = '/users/|id|/recommended',
  PATCH_ADD_USER_TO_SUPPORT = '/users/|id|/support',
  PATCH_RESET_GREETING_MESSAGE = '/users/|id|/greeting-message/reset',

  GET_WITHDRAWAL_LIST = '/payouts',
  POST_WITHDRAW = '/payouts',
  GET_PAYMENTS = '/payments',
  GET_SERVICE_MONEY = '/service-money',

  GET_MASS_MESSAGE = '/mailing',
  PATCH_MASS_MESSAGE_STATUS = '/mailing/|id|',

  GET_HIDE_TOGGLE = '/post/hide-toggle',
  GET_DELETE_POST = '/post/delete',
  GET_RESET_POST_TEXT = '/post/reset-text',
  GET_RESET_POST_PRICE = '/post/reset-price',

  GET_AFFILIATE_PROGRAM = '/credits-settings',
  POST_AFFILIATE_PROGRAM = '/credits-settings/update',

  GET_UPDATE_USER_DATA = '/user/update',
  GET_BLOCK_USER = '/user/ban',
  GET_RESET_USER_AVATAR = '/user/reset-avatar',

  POST_GIVE_MONEY = '/add-credits',
  GET_ADDET_MONEY_LIST = '/added-credits',

  GET_REPLENISHMENT_PACKAGES_UPDATE = '/sale-of-credits/update',

  GET_DELETE_MESSAGE = '/messages/|id|',

  POST_UPDATE_WITHDRAWAL = '/money-transfer/update-status',

  GET_COMMISSION_SETTINGS = '/influencers-settings',
  GET_COMMISSION_SETTINGS_UPDATE = '/influencers-settings/update',

  PATCH_UPDATE_PARTNER_PROGRAM_STATUS = '/users/|id|/affiliate-system'
}
