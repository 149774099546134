<section class="action-confirmer-dialog dialog">
  <h2 class="dialog-title">{{ text }}</h2>

  <section class="actions">
    <button 
      class="button full confirm" 
      (click)="confirm()"
    >
      Подтвердить действие
    </button>

    <button 
      mat-dialog-close 
      class="button full reject"
    >
      Отменить действие
    </button>
  </section>
</section>