import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-operator-dialog',
  templateUrl: './create-new-operator-dialog.component.html',
  styleUrls: ['./create-new-operator-dialog.component.scss']
})
export class CreateNewOperatorDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false; 
  public operatorType: any

  constructor(
    private dialogRef: MatDialogRef<CreateNewOperatorDialogComponent>,
    private http: HttpService
  ) {

    const operatorName = new FormControl('', [
      Validators.required
    ]);
  
    const email = new FormControl('', [
      Validators.email
    ]);

    const password = new FormControl('', [
      Validators.required
    ]);

    const operatorPercentage = new FormControl('', [
      Validators.required
    ]);

    this.groupForm = new FormGroup({
      operatorName,
      operatorPercentage,
      email,
      password
    });
  }

  ngOnInit() {
  }

  selectOperatorType(event: any) {
    this.operatorType = event.target.value;
  }

  createOperator() {
    if(this.groupForm.invalid || this.isRequested) return;

    let operatorName: string = this.groupForm.controls.operatorName.value;
    let operatorPercentage: string = this.groupForm.controls.operatorPercentage.value;
    let email: string = this.groupForm.controls.email.value;
    let password: string = this.groupForm.controls.password.value;

    this.http.post(
      HttpRequests.POST_OPERATORS,
      { operatorName, operatorPercentage, email, password, operatorType: this.operatorType }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}