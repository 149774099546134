import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update-model-work-info-dialog',
  templateUrl: './update-model-work-info-dialog.component.html',
  styleUrls: ['./update-model-work-info-dialog.component.scss']
})
export class UpdateModelWorkInfoDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      modelID: string,
      workInfo: string,
    },
    private dialogRef: MatDialogRef<UpdateModelWorkInfoDialogComponent>,
    private http: HttpService,
  ) {

    const workInfo = new FormControl('', []);

    this.groupForm = new FormGroup({
      workInfo,
    });
  }

  ngOnInit() {
  }

  updateNickname() {
    // if(this.groupForm.invalid || this.isRequested) return;

    let workInfo: string = this.groupForm.controls.workInfo.value;

    this.http.patch(
      HttpRequests.PATCH_UPDATE_MODEL_WORK_INFO.replace('|id|', this.data.modelID),
      { workInfo }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}