import { IDevice } from '../interfaces/device.interface';
import { Browser } from '../enums/browser.enum';
import { Os } from '../enums/os.enum';

// export function getBrowserName(): Browser {
// 	let isOpera: boolean = false,
// 		isEdge: boolean = false

// 	if (
// 		navigator.userAgent.indexOf('Edge') !== -1 &&
// 		(!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)
// 	) {
// 		isEdge = true
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.EdgeMobile
// 		}
// 		return Browser.Edge
// 	}
// 	if (!!(window as any).opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
// 		isOpera = true
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.EdgeMobile
// 		}
// 		return Browser.Opera
// 	}
// 	if (navigator.userAgent.match(/\bYaBrowser\/(\d+)/)) {
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.YandexMobile
// 		}
// 		return Browser.Yandex
// 	}
// 	if (typeof (window as any).InstallTrigger !== 'undefined') {
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.FirefoxMobile
// 		}
// 		return Browser.Firefox
// 	}
// 	if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.SafariMobile
// 		}
// 		return Browser.Safari
// 	}
// 	if (!!(window as any).chrome && !isOpera) {
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.ChromeMobile
// 		}
// 		return Browser.Chrome
// 	}
// 	if (
// 		typeof document !== 'undefined' &&
// 		!!(document as any).documentMode &&
// 		!isEdge
// 	) {
// 		if (window.DetectRTC.isMobileDevice) {
// 			return Browser.IEMobile
// 		}
// 		return Browser.IE
// 	}

// 	return Browser.Unknown
// }

export function getDeviceInfo(): IDevice {
	let unknown = '-'

	// browser
	let nVer = navigator.appVersion
	let nAgt = navigator.userAgent
	let browser: Browser = Browser.Unknown;
	let version = '' + parseFloat(navigator.appVersion)
	let majorVersion = parseInt(navigator.appVersion, 10)
	let nameOffset, verOffset, ix


	// mobile version
	let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer)

	// Opera
	if ((verOffset = nAgt.indexOf('Opera')) != -1) {
		browser = Browser.Opera


		version = nAgt.substring(verOffset + 6)
		if ((verOffset = nAgt.indexOf('Version')) != -1) {
			version = nAgt.substring(verOffset + 8)
		}
	}
	// Opera Next
	if ((verOffset = nAgt.indexOf('OPR')) != -1) {
		browser = Browser.Opera
		version = nAgt.substring(verOffset + 4)
	}
	// Edge
	else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
		browser = Browser.Edge
		version = nAgt.substring(verOffset + 5)
	}
	// Yandex
	else if (nAgt.match(/\bYaBrowser\/(\d+)/)) {
		browser = Browser.Yandex

	}
	// MSIE
	else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
		browser = Browser.IE
		version = nAgt.substring(verOffset + 5)
	}
	// Chrome
	else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
		browser = Browser.Chrome
		version = nAgt.substring(verOffset + 7)
	}
	// Safari
	else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
		browser = Browser.Safari
		version = nAgt.substring(verOffset + 7)
		if ((verOffset = nAgt.indexOf('Version')) != -1) {
			version = nAgt.substring(verOffset + 8)
		}
	}
	// Firefox
	else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
		browser = Browser.Firefox
		version = nAgt.substring(verOffset + 8)
	}
	// MSIE 11+
	else if (nAgt.indexOf('Trident/') != -1) {
		browser = Browser.IE
		version = nAgt.substring(nAgt.indexOf('rv:') + 3)
	}
	// Other browsers
	else if (
		(nameOffset = nAgt.lastIndexOf(' ') + 1) <
		(verOffset = nAgt.lastIndexOf('/'))
	) {
		browser = Browser.Unknown
	}
	// trim the version string
	if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix)
	if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix)
	if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix)

	majorVersion = parseInt('' + version, 10)
	if (isNaN(majorVersion)) {
		version = '' + parseFloat(navigator.appVersion)
		majorVersion = parseInt(navigator.appVersion, 10)
	}

	// cookie
	let cookieEnabled = navigator.cookieEnabled ? true : false

	if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
		document.cookie = 'testcookie'
		cookieEnabled = document.cookie.indexOf('testcookie') != -1 ? true : false
	}

	// system
	let os = Os.Unknown
	let clientStrings = [
		{ s: Os.Windows, r: /(Windows 10.0|Windows NT 10.0)/ },
		{ s: Os.Windows, r: /(Windows 8.1|Windows NT 6.3)/ },
		{ s: Os.Windows, r: /(Windows 8|Windows NT 6.2)/ },
		{ s: Os.Windows, r: /(Windows 7|Windows NT 6.1)/ },
		{ s: Os.Windows, r: /Windows NT 6.0/ },
		{ s: Os.Windows, r: /Windows NT 5.2/ },
		{ s: Os.Windows, r: /(Windows NT 5.1|Windows XP)/ },
		{ s: Os.Windows, r: /(Windows NT 5.0|Windows 2000)/ },
		{ s: Os.Windows, r: /(Win 9x 4.90|Windows ME)/ },
		{ s: Os.Windows, r: /(Windows 98|Win98)/ },
		{ s: Os.Windows, r: /(Windows 95|Win95|Windows_95)/ },
		{ s: Os.Windows, r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ s: Os.Windows, r: /Windows CE/ },
		{ s: Os.Windows, r: /Win16/ },
		{ s: Os.Android, r: /Android/ },
		{ s: Os.OpenBSD, r: /OpenBSD/ },
		{ s: Os.SunOS, r: /SunOS/ },
		{ s: Os.Linux, r: /(Linux|X11)/ },
		{ s: Os.iOS, r: /(iPhone|iPad|iPod)/ },
		{ s: Os.MacOS, r: /Mac OS X/ },
		{ s: Os.MacOS, r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ s: Os.QNX, r: /QNX/ },
		{ s: Os.UNIX, r: /UNIX/ },
		{ s: Os.BeOS, r: /BeOS/ },
		{
			s: Os.SearchBot,
			r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
		}
	]
	for (let id in clientStrings) {
		let cs = clientStrings[id]
		if (cs.r.test(nAgt)) {
			os = cs.s
			break
		}
	}

	let osVersion: string = unknown

	if (/Windows/.test(os)) {
		osVersion = /Windows (.*)/.exec(os)[1]
		os = Os.Windows
	}

	switch (os) {
		case Os.MacOS:
			osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1]
			break

		case Os.Android:
			osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1]
			break

		case Os.iOS:
			(osVersion as any) = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
			osVersion = osVersion[1] + '.' + osVersion[2] + '.' + ((osVersion as any)[3] | 0)
			break
	}

	return {
		browser: (browser as Browser),
		browserVersion: version,
		mobile: mobile,
		os: os,
		osVersion: osVersion
	}
}
