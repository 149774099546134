import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-model-dialog',
  templateUrl: './create-new-model-dialog.component.html',
  styleUrls: ['./create-new-model-dialog.component.scss']
})
export class CreateNewModelDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false; 

  constructor(
    private dialogRef: MatDialogRef<CreateNewModelDialogComponent>,
    private http: HttpService
  ) {

    const modelNickname = new FormControl('', [
      Validators.required
    ]);
    const modelEmail = new FormControl('', [
      Validators.email
    ]);
    const modelPassword = new FormControl('', [
      Validators.required
    ]);

    this.groupForm = new FormGroup({
      modelNickname,
      modelEmail,
      modelPassword,
    });
  }

  ngOnInit() {
  }

  createModel() {
    if(this.groupForm.invalid || this.isRequested) return;

    let nickname: string = this.groupForm.controls.modelNickname.value;
    let email: string = this.groupForm.controls.modelEmail.value;
    let password: string = this.groupForm.controls.modelPassword.value;

    this.http.post(
      HttpRequests.POST_MODELS,
      { nickname, email, password }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}