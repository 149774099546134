<section class="add-to-recommended-dialog dialog">
  <h2 class="dialog-title">Добавить новую модель</h2>

  <form class="id-form" [formGroup]="groupForm">
    <div class="input-field">
      <input
        type="text"
        placeholder="никнейм"
        formControlName="modelNickname"
        id="id"
      />
    </div>
    <div class="input-field">
      <input
        type="email"
        placeholder="email"
        formControlName="modelEmail"
        id="id"
      />
    </div>
    <div class="input-field">
      <input
        type="password"
        placeholder="пароль"
        formControlName="modelPassword"
        id="id"
      />
    </div>
  </form>

  <section class="actions">
    <button 
      class="button full confirm" 
      (click)="createModel()"
    >
      Добавить
    </button>

    <button 
      mat-dialog-close 
      class="button full reject"
    >
      Отменить
    </button>
  </section>
</section>
