import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainWrapperComponent } from './wrapper/main-wrapper/main-wrapper.component';
import { AuthWrapperComponent } from './wrapper/auth-wrapper/auth-wrapper.component';
import { OnlyAuthUsersGuard } from './guards/only-auth-users.guard';
import { OnlyNotAuthUsersGuard } from './guards/only-not-auth-users.guard';

const routes: Routes = [
  {
    path: '', canActivate: [OnlyAuthUsersGuard], component: MainWrapperComponent, children: [
      { path: '', redirectTo: 'models', pathMatch: 'full' },
      { path: 'profile-agency', loadChildren: () => import('./profile-agency/profile-agency.module').then(m => m.ProfileAgencyModule) },
      { path: 'models', loadChildren: () => import('./models/models.module').then(m => m.ModelsModule) },     
      { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule) },     
      { path: 'model', loadChildren: () => import('./model-profile/model-profile.module').then(m => m.ModelProfileModule) },
      { path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule) },
      { path: 'group', loadChildren: () => import('./group-info/group-info.module').then(m => m.GroupInfoModule) },
      { path: 'operators', loadChildren: () => import('./operators/operators.module').then(m => m.OperatorsModule) },
      { path: 'money', loadChildren: () => import('./money/money.module').then(m => m.MoneyModule) },
      { path: 'money-withdrawal-operators', loadChildren: () => import('./money-withdrawal-operators/money-withdrawal-operators.module').then(m => m.MoneyWithdrawalOperatorsModule) },
      { path: 'mass-messaging', loadChildren: () => import('./mass-messaging/mass-messaging.module').then(m => m.MassMessagingModule) },
      { path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule) },     
      { path: 'posts', loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule) },
    ]
  },
  {
    path: 'auth', component: AuthWrapperComponent, canActivate: [OnlyNotAuthUsersGuard], children: [
      { path: '', loadChildren: () => import('./auth/login-owner/login-owner.module').then(m => m.LoginOwnerModule) },
    ],
  },
  { path: '**', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
