import { Injectable } from '@angular/core';
import { IAgencyModel } from '../interfaces/agency-model';

@Injectable({ providedIn: 'root' })
export class GalleryService {
  public files: number = 0;

  constructor() { }

  fileUploaded() {
    this.files -= 1;
  }
}
