import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update-model-nickname-dialog',
  templateUrl: './update-model-nickname-dialog.component.html',
  styleUrls: ['./update-model-nickname-dialog.component.scss']
})
export class UpdateModelNicknameDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { modelID: string},
    private dialogRef: MatDialogRef<UpdateModelNicknameDialogComponent>,
    private http: HttpService,
  ) {
    let nicknamePattern = '^[a-zA-Z]+$';

    const nickname = new FormControl('', [
      Validators.required,
      Validators.pattern(nicknamePattern)
    ]);

    this.groupForm = new FormGroup({
      nickname,
    });
  }

  ngOnInit() {
  }

  updateNickname() {
    if(this.groupForm.invalid || this.isRequested) return;

    let nickname: string = this.groupForm.controls.nickname.value;

    this.http.patch(
      HttpRequests.PATCH_UPDATE_MODEL_NICKNAME.replace('|id|', this.data.modelID),
      { nickname }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}