import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { HttpRequests } from 'src/app/enums/http-requests';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update-agency-name-dialog',
  templateUrl: './update-agency-name-dialog.component.html',
  styleUrls: ['./update-agency-name-dialog.component.scss']
})
export class UpdateAgencyNameDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  public groupForm: FormGroup;
  public isRequested: boolean = false; 

  constructor(
    private dialogRef: MatDialogRef<UpdateAgencyNameDialogComponent>,
    private http: HttpService
  ) {

    const agencyName = new FormControl('', [
      Validators.required
    ]);

    this.groupForm = new FormGroup({
      agencyName,
    });
  }

  ngOnInit() {
  }

  updateAgencyName() {
    if(this.groupForm.invalid || this.isRequested) return;

    let agencyName: string = this.groupForm.controls.agencyName.value;

    this.http.patch(
      HttpRequests.PATCH_SETTINGS_UPDATE_NAME,
      { agencyName }
    )
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      })
  }
}