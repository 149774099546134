import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { Inject, Injector, PLATFORM_ID, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { DialogService } from '../services/dialog.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { SnackBarTypes } from '../enums/snackbar-types.enum';

@Injectable()
export class HttpResponceInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private injector: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const snackBar = this.injector.get(MatSnackBar);
    const auth = this.injector.get(AuthService);
    const dialog = this.injector.get(DialogService);

    return next.handle(request).pipe(
      tap(
        data => {},
        error => {
          if (error instanceof HttpErrorResponse) {

            if(error.status === 500) {
              snackBar.openFromComponent(SnackbarComponent, {
                data: { 
                  text: 'Неизвестная ошибка на сервере',
                  type: SnackBarTypes.FAIL
                },
                verticalPosition: 'top',
                duration: 3000,
              });
            } else if (error.status === 401) {
              auth.signOut()
            } else {
              if(error.error === "404 page not found") {
                snackBar.openFromComponent(SnackbarComponent, {
                  data: { 
                    text: 'Not found',
                    type: SnackBarTypes.FAIL
                  },
                  verticalPosition: 'top',
                  duration: 3000,
                });
              } else if('code' in error.error) { 
                switch (error.error.code) {
                  case 3001:
                    snackBar.openFromComponent(SnackbarComponent, {
                      data: { 
                        text: 'Неправильный запрос',
                        type: SnackBarTypes.FAIL
                      },
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                    break;
                  case 3002:
                    auth.signOut();
                    break;
                  case 3003:
                    snackBar.openFromComponent(SnackbarComponent, {
                      data: { 
                        text: 'Неправильный email',
                        type: SnackBarTypes.FAIL
                      },
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                    break;
                  case 3004:
                    snackBar.openFromComponent(SnackbarComponent, {
                      data: { 
                        text: 'Неправильный пароль',
                        type: SnackBarTypes.FAIL
                      },
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                    break;
                  case 3005:
                    snackBar.openFromComponent(SnackbarComponent, {
                      data: { 
                        text: 'Ресурс не найден',
                        type: SnackBarTypes.FAIL
                      },
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                    break;
                  case 3006:
                    snackBar.openFromComponent(SnackbarComponent, {
                      data: { 
                        text: 'Ресурс уже существует',
                        type: SnackBarTypes.FAIL
                      },
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                    break;
                  default:
                    break;
                }
              }
            }

            if (error.statusText === 'Неизвестная ошибка') {
              snackBar.openFromComponent(SnackbarComponent, {
                data: { 
                  text: 'Неизвестная ошибка',
                  type: SnackBarTypes.FAIL
                },
                verticalPosition: 'top',
                duration: 3000,
              });
            }
        }
      }),
      map(data => {
        if (data instanceof HttpResponse) {
          if (data.body) {
            if ('avatar' in data.body && !data.body.avatar) {
              data.body.avatarSmall = 'assets/icons/default-user.svg';
            }
          }
        }

        return data;
      })
    );
  }
}
