import {
  Component,
  OnInit,
  Input,
  Inject,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie';
import { Cookies } from 'src/app/enums/cookies.enum';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  public links: Array<{ name: string; icon: string, iconActive: string, link?: string, method?: string }>

  public currentUrl = '';

  public isOpen: boolean = true;

  public isOwner: boolean = true;

  public isLoadingСontent: boolean = true;

  @Input()
  public swipe$: Subject<string>;
  constructor(
    private router: Router,
    private auth: AuthService,
    @Inject(CookieService) private cookie: CookieService,
  ) {
    this.currentUrl = window.location.pathname;

    this.isOpen = (this.cookie.get(Cookies.IS_TOOLBAR_OPEN) === 'true');

    this.isOwner = (this.cookie.get(Cookies.ACCESS_ADMIN) === 'true');
    this.isLoadingСontent = (this.cookie.get(Cookies.ACCESS_LOADING_СONTENT) === 'true');

    if (this.isOwner) {
      this.links = [
        { name: 'Агенство', link: 'profile-agency', icon: 'assets/menu/dashboard.svg', iconActive: 'assets/menu/dashboard-active.svg' },
        { name: 'Деньги', link: 'money', icon: 'assets/menu/balances.svg', iconActive: 'assets/menu/balances-active.svg' },
        { name: 'Группы', link: 'groups', icon: 'assets/menu/finance.svg', iconActive: 'assets/menu/finance-active.svg' },
        { name: 'Операторы', link: 'operators', icon: 'assets/menu/statistic.svg', iconActive: 'assets/menu/statistic-active.svg' },
        { name: 'Выплаты операторам', link: 'money-withdrawal-operators', icon: 'assets/menu/balances.svg', iconActive: 'assets/menu/balances-active.svg' },
        { name: 'Модели', link: 'models', icon: 'assets/menu/members.svg', iconActive: 'assets/menu/members-active.svg' },
        { name: 'Публикации', link: 'posts', icon: 'assets/menu/posts.svg', iconActive: 'assets/menu/posts-active.svg' },
        { name: 'Сообщения', link: 'messages', icon: 'assets/menu/messages.svg', iconActive: 'assets/menu/messages-active.svg' },
        { name: 'Массовые рассылки', link: 'mass-messaging', icon: 'assets/menu/messaging.svg', iconActive: 'assets/menu/messaging-active.svg' },
      ];
    } else {
      if (this.isLoadingСontent) {
        this.links = [
          { name: 'Модели', link: 'models', icon: 'assets/menu/members.svg', iconActive: 'assets/menu/members-active.svg' },
        ];
      } else {
        this.links = [
          { name: 'История дохода', link: 'money', icon: 'assets/menu/balances.svg', iconActive: 'assets/menu/balances-active.svg' },
          { name: 'Деньги выплачены', link: 'money-withdrawal-operators', icon: 'assets/menu/balances.svg', iconActive: 'assets/menu/balances-active.svg' },
          { name: 'Модели', link: 'models', icon: 'assets/menu/members.svg', iconActive: 'assets/menu/members-active.svg' },
          { name: 'Публикации', link: 'posts', icon: 'assets/menu/posts.svg', iconActive: 'assets/menu/posts-active.svg' },
          { name: 'Сообщения', link: 'messages', icon: 'assets/menu/messages.svg', iconActive: 'assets/menu/messages-active.svg' },
          { name: 'Массовые рассылки', link: 'mass-messaging', icon: 'assets/menu/messaging.svg', iconActive: 'assets/menu/messaging-active.svg' },
        ];
      }
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => (this.currentUrl = window.location.pathname));
  }

  toggle() {
    if (window.innerWidth <= 768) return;

    this.isOpen = !this.isOpen;
    this.cookie.put(Cookies.IS_TOOLBAR_OPEN, `${this.isOpen}`)
  }

  runMethod(method: string) {
    if (!method) return;

    this[method]();
  }

  signOut() {
    this.auth.signOut();
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.isOpen = false;   
    }
  }
}
