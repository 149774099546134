<section class="add-to-recommended-dialog dialog">
  <h2 class="dialog-title">Изменить количество бонусов</h2>

  <form class="id-form" [formGroup]="groupForm">
    <span class="input-name">После регистрации бесплатных сообщений:</span>
    <div class="input-field">
      <input
        type="number"
        formControlName="messages"
        id="id"
        value="{{ data.signUPFreeMessages}}"
      />
    </div>
    <span class="input-name">После регистрации бесплатных просмотров постов:</span>
    <div class="input-field">
      <input
        type="number"
        formControlName="posts"
        id="id"
        value="{{ data.signUPFreeSpyPosts}}"
      />
    </div>
  </form>

  <section class="actions">
    <button 
      class="button full confirm" 
      (click)="updateBonuses()"
    >
      Изменить
    </button>

    <button 
      mat-dialog-close 
      class="button full reject"
    >
      Отменить
    </button>
  </section>
</section>
