import { Component, Input, OnInit } from '@angular/core';
import { IAgencyModel } from 'src/app/interfaces/agency-model';
import { AgencyModelService } from 'src/app/services/agency-model.service';

@Component({
  selector: 'app-show-model-info',
  templateUrl: './show-model-info.component.html',
  styleUrls: ['./show-model-info.component.scss']
})
export class ShowModelInfoComponent implements OnInit {
  @Input()
  public activeAgencyModel: IAgencyModel;

  constructor(
    private agencyModel: AgencyModelService,
  ) {
    this.activeAgencyModel = this.agencyModel.activeAgencyModel;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.agencyModel.activeAgencyModel = null;
  }
}
