<section class="add-to-recommended-dialog dialog">
  <h2 class="dialog-title">Добавить новую группу</h2>

  <form class="id-form" [formGroup]="groupForm">
    <div class="input-field">
      <input
        type="text"
        placeholder="Название группы"
        formControlName="agencyGroupName"
        id="id"
      />
    </div>
    <select (change)="selectGroupType($event)" class="type-select">
      <option [value]="0"><span>Работа с моделями</span></option>
      <option [value]="1"><span>Работа с контентом</span></option>
    </select>
  </form>

  <section class="actions">
    <button 
      class="button full confirm" 
      (click)="createGroup()"
    >
      Добавить
    </button>

    <button 
      mat-dialog-close 
      class="button full reject"
    >
      Отменить
    </button>
  </section>
</section>
