import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WrapperModule } from './wrapper/wrapper.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { AuthService } from './services/auth.service';
import { AgencyModelService } from './services/agency-model.service';
import { DialogService } from './services/dialog.service';
import { HttpService } from './services/http.service';
import { LoggerService } from './services/logger.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CookieModule } from 'ngx-cookie';
import { HttpResponceInterceptor } from './interceptors/http-responce.interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActionConfirmerDialogComponent } from './modals/action-confirmer-dialog/action-confirmer-dialog.component';
import { ViewImageDialogComponent } from './shared/dialogs/view-image-dialog/view-image-dialog.component';
import { ViewVideoDialogComponent } from './shared/dialogs/view-video-dialog/view-video-dialog.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { ViewAvatarsDialogComponent } from './shared/dialogs/view-avatars-dialog/view-avatars-dialog.component';
import { CreateNewGroupDialogComponent } from './modals/create-new-group-dialog/create-new-group-dialog.component';
import { ShowModelInfoComponent } from './modals/show-model-info/show-model-info.component';
import { CreateNewOperatorDialogComponent } from './modals/create-new-operator-dialog/create-new-operator-dialog.component';
import { CreateNewModelDialogComponent } from './modals/create-new-model-dialog/create-new-model-dialog.component';
import { UpdateAgencyNameDialogComponent } from './modals/update-agency-name-dialog/update-agency-name-dialog.component';
import { UpdateAgencyEmailDialogComponent } from './modals/update-agency-email-dialog/update-agency-email-dialog.component';
import { UpdateAgencyPasswordDialogComponent } from './modals/update-agency-password-dialog/update-agency-password-dialog.component';
import { UpdateAgencyBitsafeIBANDialogComponent } from './modals/update-agency-bitsafe-iban-dialog/update-agency-bitsafe-iban-dialog.component';
import { OperatorNewPasswordDialogComponent } from './modals/operator-new-password-dialog/operator-new-password-dialog.component';
import { UpdateModelNicknameDialogComponent } from './modals/update-model-nickname-dialog/update-model-nickname-dialog.component';
import { GalleryService } from './services/gallery.service';
import { UpdateOperatorPercentageDialogComponent } from './modals/update-operator-percent-dialog/update-operator-percentage-dialog.component';
import { UpdateModelWorkInfoDialogComponent } from './modals/update-model-work-info-dialog/update-model-work-info-dialog.component';
import { UpdateAgencyBonusesDialogComponent } from './modals/update-agency-bonuses-dialog/update-agency-bonuses-dialog.component';
@NgModule({
  declarations: [
    AppComponent,
    ActionConfirmerDialogComponent,
    UpdateAgencyNameDialogComponent,
    UpdateAgencyBonusesDialogComponent,
    UpdateAgencyEmailDialogComponent,
    UpdateAgencyPasswordDialogComponent,
    UpdateAgencyBitsafeIBANDialogComponent,
    ViewImageDialogComponent,
    ViewVideoDialogComponent,
    SnackbarComponent,
    CreateNewGroupDialogComponent,
    CreateNewOperatorDialogComponent,
    OperatorNewPasswordDialogComponent,
    UpdateModelNicknameDialogComponent,
    UpdateModelWorkInfoDialogComponent,
    UpdateOperatorPercentageDialogComponent,
    CreateNewModelDialogComponent,
    ViewAvatarsDialogComponent,
    ShowModelInfoComponent,
  ],
  entryComponents: [
    ActionConfirmerDialogComponent,
    UpdateAgencyNameDialogComponent,
    UpdateAgencyBonusesDialogComponent,
    UpdateAgencyEmailDialogComponent,
    UpdateAgencyPasswordDialogComponent,
    UpdateAgencyBitsafeIBANDialogComponent,
    ViewImageDialogComponent,
    ViewVideoDialogComponent,
    SnackbarComponent,
    CreateNewGroupDialogComponent,
    CreateNewOperatorDialogComponent,
    OperatorNewPasswordDialogComponent,
    UpdateModelNicknameDialogComponent,
    UpdateModelWorkInfoDialogComponent,
    UpdateOperatorPercentageDialogComponent,
    CreateNewModelDialogComponent,
    ShowModelInfoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,

    CookieModule.forRoot(),

    WrapperModule,

    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
  ],
  providers: [
    AuthService,
    AgencyModelService,
    GalleryService,
    DialogService,
    HttpService,
    LoggerService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponceInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
