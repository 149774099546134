import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProfile } from 'src/app/interfaces/profile.inteface';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpService } from 'src/app/services/http.service';
import { ViewImageDialogComponent } from '../view-image-dialog/view-image-dialog.component';
import { HttpRequests } from '../../../enums/http-requests';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActionConfirmerDialogComponent } from 'src/app/modals/action-confirmer-dialog/action-confirmer-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { SnackBarTypes } from 'src/app/enums/snackbar-types.enum';

@Component({
  selector: 'app-view-avatars-dialog',
  templateUrl: './view-avatars-dialog.component.html',
  styleUrls: ['./view-avatars-dialog.component.scss']
})

export class ViewAvatarsDialogComponent implements OnInit {
  private unsubscriber$: Subject<any> = new Subject();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public user: IProfile,
    public dialogRef: MatDialogRef<ViewAvatarsDialogComponent>,
    private dialog: DialogService,
    private http: HttpService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  viewImage(avatarFull): void {
    this.dialog.open(
      ViewImageDialogComponent,
      {
        panelClass: 'fullView',
        data: avatarFull
      }
    )
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
