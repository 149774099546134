<section [ngClass]="{'closed' : !isOpen}" class="navigation">

  <div class="logo-and-close">
    <h1 *ngIf="isOpen" class="logo">
      Панель агенства
    </h1>

    <button (click)="toggle()" class="close-btn">
      <img src="assets/menu/close-menu.svg" alt="Close menu">
    </button>
  </div>

  <nav class="links">
    <div class="link-wrapper" *ngFor="let link of links" [ngClass]="{ active: currentUrl.includes(link.link) }"
      class="link">
      <a class="link-item" *ngIf="link.link; else methodItem" [routerLink]="'/' + link.link">
        <img *ngIf="!currentUrl.includes(link.link)" class="link-icon" [src]="link.icon" alt="Menu icon">
        <img *ngIf="currentUrl.includes(link.link)" class="link-icon" [src]="link.iconActive" alt="Menu icon">
        {{ link.name }}
      </a>

      <ng-template #methodItem>
        <a class="method-item" routerLink="." (click)="runMethod(link.method)">
          <img class="link-icon" [src]="link.icon" alt="Menu icon">
          {{ link.name }}
        </a>
      </ng-template>

    </div>
  </nav>

  <button (click)="signOut()" class="sign-out-btn">
    Выйти
  </button>

</section>

<!-- <button #openButton class="open-button" (click)="toggle()">
  <mat-icon>menu</mat-icon>
</button> -->

<!-- <div class="backdrop" #backdrop (click)="toggle()"></div> -->
