import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivate
} from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { HttpService } from '../services/http.service';
import { AuthService } from '../services/auth.service';
import { LoggerService } from '../services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class OnlyNotAuthUsersGuard implements CanActivate {
  constructor(
    private http: HttpService,
    private auth: AuthService,
    private logger: LoggerService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable((observer: Observer<boolean>) => {
      this.logger.warn('OnlyAuthenticatedUsersGuard', this.auth.isLogged());

      if (this.auth.isLogged()) {
        this.router.navigate(['/users']);
        observer.next(false);
      } else {
        observer.next(true);
      }
    });
  }
}
