import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivate
} from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { HttpService } from '../services/http.service';
import { AuthService } from '../services/auth.service';
import { LoggerService } from '../services/logger.service';
import { HttpRequests } from '../enums/http-requests';

@Injectable({
  providedIn: 'root'
})
export class OnlyAuthUsersGuard implements CanActivate {
  constructor(
    private http: HttpService,
    private auth: AuthService,
    private logger: LoggerService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable((observer: Observer<boolean>) => {
      this.logger.warn('OnlyAuthenticatedUsersGuard', this.auth.isLogged());
      if (this.auth.isLogged()) {
        // console.warn('1');
        observer.next(true);

        // this.http
        //   .get<{ email: string; adminID: string }>(
        //     HttpRequests.GET_ADMIN_PROFILE
        //   )
        //   .subscribe(user => {
        //     // console.warn('3', user);
        //     if ('code' in user) {
        //       this.auth.signOut();
        //       observer.next(false);
        //     } else {
        //       // this.user.User = user;
        //       observer.next(true);
        //     }
        //   });
      } else {
        // console.warn('2');
        this.router.navigateByUrl('/auth');
        observer.next(false);
      }
    });
  }
}
